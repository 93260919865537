@media (min-width: 1024) {
	.main {
		flex-direction: row;
		padding-left: 16rem;
		padding-right: 16rem;
		gap: 16.8rem;
		padding-top: 8.7rem;
	}

	.picture404 {
		min-width: 51.2rem;
	}

	.text1 {
		font-size: 7rem;
		color: var(--violet-dark-web-space);
		padding-bottom: 3.2rem;
	}

	.text2 {
		font-size: 3rem;
		color: var(--violet-light-web-space);
		padding-bottom: 3rem;
		line-height: 3.2rem;
	}

	.text3 {
		font-size: 3.2rem;
		padding-bottom: 2.4rem;
		line-height: 3.4rem;
	}

	.link {
		align-self: flex-start;
	}

	.container {
		min-width: 92.2rem;
	}
}
