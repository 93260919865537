@media (width >= 1024px) {
  .main {
    flex-direction: row;
    gap: 16.8rem;
    padding-top: 8.7rem;
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .picture404 {
    min-width: 51.2rem;
  }

  .text1 {
    color: var(--violet-dark-web-space);
    padding-bottom: 3.2rem;
    font-size: 7rem;
  }

  .text2 {
    color: var(--violet-light-web-space);
    padding-bottom: 3rem;
    font-size: 3rem;
    line-height: 3.2rem;
  }

  .text3 {
    padding-bottom: 2.4rem;
    font-size: 3.2rem;
    line-height: 3.4rem;
  }

  .link {
    align-self: flex-start;
  }

  .container {
    min-width: 92.2rem;
  }
}
/*# sourceMappingURL=no-encontrado.7819cf5a.css.map */
